import "./styles.css";
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

const Landing = React.lazy(() => import("./views/Landing"));
const ViewWrapper = React.lazy(() => import("./views/ViewWrapper"));
const Home = React.lazy(() => import("./views/Home"));
const Events = React.lazy(() => import("./views/Events"));
const Travel = React.lazy(() => import("./views/Travel"));
const Rsvp = React.lazy(() => import("./views/Rsvp"));
const Gifts = React.lazy(() => import("./views/Gifts"));

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (!localStorage.getItem("token")) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
}

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              localStorage.getItem("token") ? (
                <ViewWrapper>
                  <Home />
                </ViewWrapper>
              ) : (
                <Landing variant="login" />
              )
            }
          />
          <Route
            path="/resetPassword"
            element={<Landing variant="resetPassword" />}
          />
          <Route
            path="/events"
            element={
              <RequireAuth>
                <ViewWrapper>
                  <Events />
                </ViewWrapper>
              </RequireAuth>
            }
          />
          <Route
            path="/travel"
            element={
              <RequireAuth>
                <ViewWrapper>
                  <Travel />
                </ViewWrapper>
              </RequireAuth>
            }
          />
          <Route
            path="/rsvp"
            element={
              <RequireAuth>
                <ViewWrapper>
                  <Rsvp />
                </ViewWrapper>
              </RequireAuth>
            }
          />
          <Route
            path="/gifts"
            element={
              <RequireAuth>
                <ViewWrapper>
                  <Gifts />
                </ViewWrapper>
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
